import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import App from './App';

// @ts-ignore
import messagesEs from './translations/es.json';
// @ts-ignore
import messagesEn from './translations/en.json';
// @ts-ignore
import messagesFr from './translations/fr.json';
// @ts-ignore
import messagesRu from './translations/ru.json';
// @ts-ignore
import messagesPt from './translations/pt.json';

const messages = {
  es: messagesEs,
  en: messagesEn,
  fr: messagesFr,
  ru: messagesRu,
  pt: messagesPt
};

const appContainer = document.getElementById('root');

const root = createRoot(appContainer as HTMLElement);

function Wrapper() {
  const [locale, setLocale] = useState<string>(navigator.language.split(/[-_]/)[0]);
  return (
    <>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages[locale as 'es' | 'en' | 'fr' | 'ru' | 'pt']}
          defaultLocale="en">
          <App setLocale={setLocale} locale={locale} />
        </IntlProvider>
      </BrowserRouter>
      <Toaster
        position="bottom-center"
        toastOptions={{
          duration: 7000,
          style: {
            fontSize: '.875rem',
            textWrap: 'pretty',
            maxWidth: '400px'
          }
        }}
      />
    </>
  );
}

root.render(<Wrapper />);
