import React, { lazy } from 'react';
import { withErrorBoundary } from '@sentry/react';
import { Route, Routes } from 'react-router-dom';
import { Localization } from './types';

const Register = lazy(() => import('./Register'));
const RegistrationPayment = lazy(() => import('./RegistrationPayment/index'));
const Steps = lazy(() => import('./Steps'));
const NoMatch404Page = lazy(() => import('../../common/components/NoMatch404Page'));
const AuthValidate = lazy(() => import('./AuthValidate'));
const ActivationPage = React.lazy(() => import('./Activation/ActivationPage'));
const ContractSigningStep = lazy(
  () => import('./Activation/ContractSigningStep/ContractSigningStep')
);
const EmailVerificationStep = lazy(
  () => import('./Activation/EmailVerificationStep/EmailVerificationStep')
);
const SignatureOfApplicationStep = lazy(
  () => import('./Activation/SignatureOfApplicationStep/SignatureOfApplicationStep')
);
const PowerOfAttorneyStep = lazy(
  () => import('./Activation/PowerOfAttorneyStep/PowerOfAttorneyStep')
);
const BusinessRegistrationStep = lazy(
  () => import('./Activation/BusinessRegistrationStep/BusinessRegistrationStep')
);
const CompleteSetupStep = lazy(() => import('./Activation/CompleteSetupStep/CompleteSetupStep'));
const AddSignature = lazy(() => import('./Activation/AddSignature/AddSignature'));

function RoutesMain({ setLocale, locale }: Localization) {
  return (
    <Routes>
      <Route element={<Register setLocale={setLocale} locale={locale} />} index />
      <Route
        element={<Steps setLocale={setLocale} locale={locale} />}
        path="register/steps/:token/:email/"
      />

      <Route element={<AuthValidate />}>
        <Route element={<RegistrationPayment locale={locale as string} />} path="payment" />
        <Route element={<ActivationPage locale={locale} setLocale={setLocale} />} path="activation">
          <Route element={<ContractSigningStep />} path="contract" />
          <Route element={<EmailVerificationStep />} path="email_verification" />
          <Route element={<SignatureOfApplicationStep />} path="signature" />
          <Route element={<PowerOfAttorneyStep />} path="power_of_attorney" />
          <Route element={<BusinessRegistrationStep />} path="business_registration" />
          <Route element={<CompleteSetupStep />} path="complete_setup" />
          <Route element={<AddSignature />} path="company_signature" />
        </Route>
      </Route>

      <Route element={<Register setLocale={setLocale} locale={locale} />} path="/*" />
      <Route path="*" element={<NoMatch404Page />} />
    </Routes>
  );
}

export default withErrorBoundary(RoutesMain, { fallback: <div>Failed to load</div> });
