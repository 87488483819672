import './App.css';
import React from 'react';
import RoutesES from './features/spain/Routes';
import RoutesMain from './features/main/Routes';
import { Localization } from './features/main/types';

export const getRoutes = (setLocale: Function, locale: string) => {
  if (process.env.REACT_APP_LOCALE_VERSION === 'es') {
    return <RoutesES />;
  }

  return <RoutesMain setLocale={setLocale} locale={locale} />;
};

function App({ setLocale, locale }: Localization) {
  return (
    <div className="App h-full" data-testid="app">
      {getRoutes(setLocale, locale)}
    </div>
  );
}

export default App;
